#terminal {
  & * {
    font-size: 1.1rem;
    line-height: 1.4rem;
    font-family: "Inconsolata", "Courier New", Courier, monospace;
  }

  .logo {
    padding-top: 0.75rem;
    font-size: 0.6rem;
    line-height: 0.34rem;

    /* Tablet */
    @media only screen and (min-width: $width--tablet-min) {
      font-size: 0.6rem;
    }
  }

  .container output {
    .power-unlocked {
      animation: power-ani 0.6s 4;
      color: #37debc;
    }
  }

  .command {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    &.line {
      .prompt {
        padding-right: 0.5rem;
      }
    }
    .prompt {
      margin: 0;
    }
    .input {
      display: flex;
      justify-content: center;
      width: 100%;
      line-height: 1rem !important;
    }
  }

  /* Tablet */
  @media only screen and (min-width: $width--tablet-min) {
    & * {
      font-size: 1.5rem;
      line-height: 1.7rem;
    }
    .logo {
      font-size: 1.2rem;
      line-height: 0.5rem;
      -webkit-font-smooth: subpixel-antialiased;
    }
    .command {
      margin-top: 2.5rem;
    }
  }
}

@keyframes power-ani {
  0% {
    color: #37debc;
  }
  50% {
    color: #2778ae;
  }
  100% {
    color: #37debc;
  }
}
