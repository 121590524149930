.github-fork-ribbon {
  position: fixed;
  top: -30px;
  right: -30px;
  font-size: 0.77rem;

  &::after {
    content: "GitHub";
  }

  &.mobile-hide,
  &.hide {
    display: none !important;
  }

  /* Tablet */
  @media only screen and (min-width: $width--tablet-min) {
    top: 0;
    right: 0;
    font-size: 1rem;

    &::after {
      content: "Fork me on GitHub";
    }
  }
}
