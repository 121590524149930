html, body {
  height: 100%;
}

body {
  height: 100%;
  width: 100%;
  overscroll-behavior: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: #000;
  margin: 0;
  padding: 0;
}

.hide {
  display: none;
}

#terminal * {
  font-family: Inconsolata, Courier New, Courier, monospace;
  font-size: 1.1rem;
  line-height: 1.4rem;
}

#terminal .logo {
  padding-top: .75rem;
  font-size: .6rem;
  line-height: .34rem;
}

@media only screen and (min-width: 550px) {
  #terminal .logo {
    font-size: .6rem;
  }
}

#terminal .container output .power-unlocked {
  color: #37debc;
  animation: .6s 4 power-ani;
}

#terminal .command {
  flex-direction: row;
  margin-top: 1.5rem;
  display: flex;
}

#terminal .command.line .prompt {
  padding-right: .5rem;
}

#terminal .command .prompt {
  margin: 0;
}

#terminal .command .input {
  width: 100%;
  justify-content: center;
  display: flex;
  line-height: 1rem !important;
}

@media only screen and (min-width: 550px) {
  #terminal * {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }

  #terminal .logo {
    -webkit-font-smooth: subpixel-antialiased;
    font-size: 1.2rem;
    line-height: .5rem;
  }

  #terminal .command {
    margin-top: 2.5rem;
  }
}

@keyframes power-ani {
  0% {
    color: #37debc;
  }

  50% {
    color: #2778ae;
  }

  100% {
    color: #37debc;
  }
}

.github-fork-ribbon {
  font-size: .77rem;
  position: fixed;
  top: -30px;
  right: -30px;
}

.github-fork-ribbon:after {
  content: "GitHub";
}

.github-fork-ribbon.mobile-hide, .github-fork-ribbon.hide {
  display: none !important;
}

@media only screen and (min-width: 550px) {
  .github-fork-ribbon {
    font-size: 1rem;
    top: 0;
    right: 0;
  }

  .github-fork-ribbon:after {
    content: "Fork me on GitHub";
  }
}

.TextTerminal {
  min-height: 100%;
}

.TextTerminal .container {
  padding: 1rem;
}

.TextTerminal * {
  font-family: Courier New, Courier, monospace;
  font-size: 1.5rem;
  line-height: 1.7rem;
}

.TextTerminal .command {
  flex-direction: row;
  align-items: center;
  margin-top: 1.5rem;
  display: flex;
}

.TextTerminal .command.line .prompt {
  padding-right: .5rem;
}

.TextTerminal .command .prompt {
  margin: 0;
}

.TextTerminal .command .input {
  width: 100%;
  background-color: #0000;
  border: none;
  outline: none;
  justify-content: center;
  margin: 0;
  padding: 0 0 0 .5rem;
  font-weight: 400;
  line-height: 1rem;
  display: flex;
}

@media only screen and (min-width: 550px) {
  .TextTerminal * {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }

  .TextTerminal .container {
    width: 90%;
  }

  .TextTerminal .command {
    margin-top: 2.5rem;
  }
}

.dark {
  background-color: #000;
}

.dark * {
  color: #fff;
}

.jinx {
  background-color: #037631;
}

.jinx * {
  color: #000;
}

.midnight {
  background-color: #03235f;
}

.midnight * {
  color: #f4d396;
}

.TextTerminal.sunset {
  background-color: #7b3103;
}

.TextTerminal.sunset * {
  color: #fadbdb;
}

/*# sourceMappingURL=index.e4558244.css.map */
