.TextTerminal {
  min-height: 100%;
}
.TextTerminal .container {
  padding: 1rem;
}
.TextTerminal * {
  font-family: "Courier New", Courier, monospace;
  font-size: 1.5rem;
  line-height: 1.7rem;
}
.TextTerminal .command {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.TextTerminal .command.line .prompt {
  padding-right: 0.5rem;
}
.TextTerminal .command .prompt {
  margin: 0;
}
.TextTerminal .command .input {
  display: flex;
  justify-content: center;
  width: 100%;
  line-height: 1rem;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0 0 0 0.5rem;
  outline: none;
  font-weight: 400;
}
@media only screen and (min-width: 550px) {
  .TextTerminal * {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }
  .TextTerminal .container {
    width: 90%;
  }
  .TextTerminal .command {
    margin-top: 2.5rem;
  }
}

.dark {
  background-color: #000;
}
.dark * {
  color: #fff;
}

.jinx {
  background-color: #037631;
}
.jinx * {
  color: black;
}

.midnight {
  background-color: #03235f;
}
.midnight * {
  color: #f4d396;
}

.TextTerminal.sunset {
  background-color: #7b3103;
}
.TextTerminal.sunset * {
  color: #fadbdb;
}
/*# sourceMappingURL=text-terminal.css.map */
