.TextTerminal {
  min-height: 100%;

  .container {
    padding: 1rem;
  }

  & * {
    font-family: "Courier New", Courier, monospace;
    font-size: 1.5rem;
    line-height: 1.7rem;
  }

  .command {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.line {
      .prompt {
        padding-right: 0.5rem;
      }
    }

    .prompt {
      margin: 0;
    }

    .input {
      display: flex;
      justify-content: center;
      width: 100%;
      line-height: 1rem;
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0 0 0 0.5rem;
      outline: none;
      font-weight: 400;
    }
  }

  @media only screen and (min-width: 550px) {
    & * {
      font-size: 1.5rem;
      line-height: 1.7rem;
    }

    .container {
      width: 90%;
    }
    .command {
      margin-top: 2.5rem;
    }
  }
}
